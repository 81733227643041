<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0 text-center">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            AgentScout
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Checkout completato con successo
        </b-card-title>
        <b-card-text class="mb-2">
          ... spiegazione che il pagamento sarà effettuato come concordato nelle modalità di contratto e che al momento non è stato transato nulla ma il contratto da adesso è attivo / operativo
        </b-card-text>
        
        <b-button variant="outline-primary" class="mb-2" @click="linkDashboardCliente()">Visualizza Licenza</b-button>

      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import router from '@/router'
import {
  BButton, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
  },
  created() {
    console.log("id_licenza -> "+router.currentRoute.params.id_licenza)
  },
  methods: {
    linkDashboardCliente(){
      this.$router.replace('/area-clienti/licenze/show/'+router.currentRoute.params.id_licenza);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
